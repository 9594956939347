import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'

import { Body, Header } from 'src/ui/typography'
import { MosaicItem } from './../../molecules'

type Props = {
  images: TImages
}

const Grid1 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 70% 26%;
  gap: 4%;
`

const Grid2 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 43% 53%;
  gap: 4%;
`

export const Tourism: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Header size="h2" as="h2">
        Туризм
      </Header>
      <HBox height={40} />
      <Grid1>
        <MosaicItem
          image={images['poland-desk']}
          imageHeight={414}
          childrenPosition="bottom-left"
          childrenWidth={318}
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            700&nbsp;₽
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Поехать в&nbsp;Польшу на&nbsp;выходные или в&nbsp;аэропорт
          </Body>
        </MosaicItem>

        <MosaicItem
          image={images['lowcost-desk']}
          imageHeight={274}
          objectPosition="top center"
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            20&nbsp;€
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Купить билет на&nbsp;лоукостер
            <br />
            из&nbsp;Гданьска в&nbsp;Рим
          </Body>
        </MosaicItem>
      </Grid1>
      <HBox height={40} />
      <Grid2>
        <MosaicItem
          image={images['visa']}
          imageHeight={442}
          childrenPosition="bottom-left"
          childrenWidth={318}
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            7&nbsp;000&nbsp;₽
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Открыть многоразовую <br />
            шенгенскую&nbsp;визу
          </Body>
        </MosaicItem>

        <MosaicItem
          image={images['car-rent-desk']}
          imageHeight={442}
          childrenPosition="top-left"
          childrenWidth={318}
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            1&nbsp;700&nbsp;₽
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Взять машину в&nbsp;аренду, чтобы <br />
            осмотреть старинные&nbsp;кирхи
          </Body>
        </MosaicItem>
      </Grid2>
    </Wrapper>
  )
}
