import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'

import { Body, Header } from 'src/ui/typography'
import { MosaicItem } from './../../molecules'
import { DINA, QA_VIKING } from './constants'

type Props = {
  images: TImages
}

const Grid1 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 70% 26%;
  gap: 4%;
`

const Grid2 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 43% 53%;
  gap: 4%;
`

export const Faq: React.FC<Props> = ({ images }) => {
  return (
    <Wrapper>
      <Header size="h2" as="h2">
        Хотите знать больше?
      </Header>
      <HBox height={40} />
      <Grid1>
        <div>
          <MosaicItem
            image={images['orel-reshka']}
            imageHeight={436}
            title="Орел и решка"
          />
          <HBox />
          <Body>{QA_VIKING}</Body>
          <HBox height={8} />
          <Body>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=-H4r35g7-XU"
            >
              Ютуб&nbsp;→
            </a>
          </Body>
        </div>
        <div>
          <MosaicItem
            image={images['alexander']}
            imageHeight={436}
            title="Alexander Yúzhda"
          />
          <HBox />
          <Body>
            Пишет, где поесть <br />и чем заняться в&nbsp;Калининграде
          </Body>
          <HBox height={8} />
          <Body>
            <a target="_blank" href="https://t.me/wheretogoklgd">
              Телеграм →
            </a>
          </Body>
        </div>
      </Grid1>
      <HBox height={40} />
      <Grid2>
        <div>
          <MosaicItem
            image={images['andrey']}
            imageHeight={436}
            title="Андрей Новожилов"
          />
          <HBox />
          <Body>
            Фотограф на&nbsp;Фольксвагене&nbsp;T3 1983&nbsp;года выпуска ездит{' '}
            <br />
            по заброшенным кирхам, немецким угодьям и&nbsp;снимает роад-видео
          </Body>
          <HBox height={8} />
          <Body>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=IR8e362M1-4&list=PLFusZxrO95QWpJFCpr2-Cg-jjkYD4dJqR&index=3"
            >
              Ютуб&nbsp;→
            </a>
          </Body>
        </div>
        <div>
          <MosaicItem
            image={images['dina']}
            imageHeight={436}
            title="Дина Лях"
          />
          <HBox />
          <Body>{DINA}</Body>
          <HBox height={8} />
          <Body>
            <a target="_blank" href="https://littlekaliningrad.ru/">
              Блог →
            </a>
          </Body>
        </div>
      </Grid2>
    </Wrapper>
  )
}
