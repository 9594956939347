import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { HBox, Wrapper } from 'src/ui/layout'
import { Hero, SwiperSlider } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { DINA, QA_VIKING } from './constants'

type Props = {
  images: TImages
}

export const Faq = ({ images }: Props) => {
  return (
    <>
      <Wrapper>
        <Header size="h4" as="h2">
          Хотите знать больше?
        </Header>
      </Wrapper>
      <HBox height={24} />
      <SwiperSlider>
        <SwiperSlide>
          <Hero
            title="Орел и решка"
            background={images['orel-reshka']}
            objectPosition="top left"
          >
            <HBox height={8} />
            <Body>{QA_VIKING}</Body>
            <HBox height={8} />
            <Body weight="medium">
              <a target="_blank" href="/">
                Ютуб →
              </a>
            </Body>
          </Hero>
        </SwiperSlide>
        <SwiperSlide>
          <Hero
            title="Alexander Yúzhda"
            background={images['alexander']}
            objectPosition="top center"
          >
            <HBox height={8} />
            <Body>
              Пишет, где поесть <br />и чем заняться в Калининграде
            </Body>
            <HBox height={8} />
            <Body weight="medium">
              <a target="_blank" href="https://t.me/wheretogoklgd">
                Телеграм →
              </a>
            </Body>
          </Hero>
        </SwiperSlide>
        <SwiperSlide>
          <Hero title="Андрей Новожилов" background={images['andrey']}>
            <HBox height={8} />
            <Body>
              Фотограф на Фольксвагене T3 1983 года выпуска ездит <br />
              по заброшенным кирхам, немецким угодьям и снимает роад-видео
            </Body>
            <HBox height={8} />
            <Body weight="medium">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=IR8e362M1-4&list=PLFusZxrO95QWpJFCpr2-Cg-jjkYD4dJqR&index=3"
              >
                Ютуб →
              </a>
            </Body>
          </Hero>
        </SwiperSlide>
        <SwiperSlide>
          <Hero title="Дина Лях" background={images['dina']}>
            <HBox height={8} />
            <Body>{DINA}</Body>
            <HBox height={8} />
            <Body>
              <a target="_blank" href="https://littlekaliningrad.ru/">
                Блог →
              </a>
            </Body>
          </Hero>
        </SwiperSlide>
      </SwiperSlider>
    </>
  )
}
