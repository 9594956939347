import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { RootLayout } from 'src/layouts'
import { HBox } from 'src/ui/layout'
import {
  Kaliningrad as Hero,
  Reasons,
  LifeLevel,
  Tourism,
  Rest,
  Faq,
} from 'src/features/kaliningrad'

import { Meta } from 'src/features/seo'

import { graphql } from 'gatsby'
import { Query } from 'hr/graphql-types'

type Props = {} & { data: Query }

const Kaliningrad: React.FC<Props> = ({ data }) => {
  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})
  const { isMobile } = useDimensions()
  return (
    <RootLayout>
      <Meta
        title="Работа в Калининграде в IT — KODE"
        description="Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды в калининградской компании KODE ✔ Ищем UX-дизайнеров, аналитиков, QA, разработчиков"
      />

      {isMobile ? <HBox /> : null}
      <Hero images={images} />
      <HBox height={48} />
      <Reasons images={images} />

      {isMobile ? <HBox height={56} /> : <HBox height={134} />}
      <LifeLevel images={images} />

      {isMobile ? <HBox height={56} /> : <HBox height={80} />}
      {/* <Tourism images={images} />
      {isMobile ? <HBox height={56} /> : <HBox height={80} />} */}
      <Rest images={images} />
      {isMobile ? <HBox height={56} /> : <HBox height={80} />}
      <Faq images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={80} />}
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/kaliningrad/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`
export default Kaliningrad
