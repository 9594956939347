import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { Hero, HeroLine } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'

import { useTheme } from 'src/ui/theme'

type Props = {
  images: TImages
}

export const Rest: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  return (
    <>
      <Wrapper>
        <Header size="h4" as="h2">
          Досуг и отдых
        </Header>
      </Wrapper>
      <HBox height={24} />
      <Hero
        title="29"
        background={images['events']}
        titleColor={theme.colors.brand.primary}
      >
        <HBox height={8} />
        <Body size="secondary">
          Крупных событий проходит в Калининграде ежегодно
        </Body>
      </Hero>
      <HBox height={32} />
      <HeroLine
        align="left"
        background={images['free-events']}
        title="0 ₽"
        titleColor={theme.colors.brand.primary}
        description="Большинство мероприятий бесплатныe"
      />
      <HBox height={32} />
      <HeroLine
        align="right"
        background={images['skyserf']}
        title="3 000 ₽"
        titleColor={theme.colors.brand.primary}
        description="Взять урок сап-сёрфинга с прокатом оборудования"
      />
      <HBox height={32} />
      <Hero
        title="27"
        background={images['beach']}
        titleColor={theme.colors.brand.primary}
      >
        <HBox height={8} />
        <Body size="secondary">Пляжей в области</Body>
      </Hero>
    </>
  )
}
