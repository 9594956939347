import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Kaliningrad: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['kaliningrad-mob']}
    title="Европа в России"
    adornment
    label="КАЛИНИНГРАД"
  >
    <HBox />
    <Body>
      Калининград иногда называют русской&nbsp;Европой, поскольку на&nbsp;этой
      территории причудливо соединяются европейский образ жизни, русские
      традиции, старинные улочки бывшего Кёнигсберга и&nbsp;современные офисы
      IT-компаний.
    </Body>
  </Hero>
)
