import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'

import { Body, Header } from 'src/ui/typography'
import { MosaicItem } from './../../molecules'

type Props = {
  images: TImages
}

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const LeftCol = styled.div``

const RightCol = styled.div``

const Quoters = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  gap: 50px;
`

export const LifeLevel: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Header size="h2" as="h2">
        Уровень жизни
      </Header>
      <HBox height={40} />
      <Grid>
        <LeftCol>
          <MosaicItem
            image={images['rent']}
            imageHeight={414}
            childrenPosition="bottom-left"
            childrenWidth={318}
          >
            <HBox height={24} />
            <Header size="h2" color={theme.colors.brand.primary}>
              25&nbsp;000&nbsp;₽
            </Header>
            <HBox height={8} />
            <Body size="secondary">
              Оплатить аренду квартиры с&nbsp;учетом&nbsp;коммуналки
            </Body>
          </MosaicItem>
          <HBox height={40} />
          <MosaicItem
            image={images['train']}
            imageHeight={868}
            childrenPosition="bottom-left"
            childrenWidth={190}
          >
            <HBox height={24} />
            <Header size="h2" color={theme.colors.brand.primary}>
            79&nbsp;₽
            </Header>
            <HBox height={8} />
            <Body size="secondary">
              Поехать&nbsp;на&nbsp;электричке на&nbsp;море
            </Body>
          </MosaicItem>
        </LeftCol>

        <RightCol>
          <Quoters>
            <div>
              <MosaicItem image={images['bar-desk']} imageHeight={700}>
                <HBox height={24} />
                <Header size="h2" color={theme.colors.brand.primary}>
                  700&nbsp;₽
                </Header>
                <HBox height={8} />
                <Body size="secondary">
                  Сходить в&nbsp;бар, выпить пива <br />
                  и&nbsp;съесть&nbsp;пиццу.
                </Body>
              </MosaicItem>
            </div>
            <div>
              <MosaicItem image={images['taxi-desk']} imageHeight={300}>
                <HBox height={24} />
                <Header size="h2" color={theme.colors.brand.primary}>
                  150&nbsp;₽
                </Header>
                <HBox height={8} />
                <Body size="secondary">Поехать на&nbsp;такси</Body>
              </MosaicItem>
              <HBox height={40} />
              <MosaicItem image={images['zeleng']} imageHeight={246}>
                <HBox height={24} />
                <Header size="h2" color={theme.colors.brand.primary}>
                  100&nbsp;₽
                </Header>
                <HBox height={8} />
                <Body size="secondary">
                  Покормить котиков в Зеленоградске
                </Body>
              </MosaicItem>
            </div>
          </Quoters>
          <HBox height={40} />
          <MosaicItem
            image={images['supermarket']}
            imageHeight={444}
            childrenPosition="bottom-left"
            childrenWidth={209}
          >
            <HBox height={24} />
            <Header size="h2" color={theme.colors.brand.primary}>
              1&nbsp;200&nbsp;₽
            </Header>
            <HBox height={8} />
            <Body size="secondary">
              Купить продукты <br />
              домой в&nbsp;супермаркете
            </Body>
          </MosaicItem>
        </RightCol>
      </Grid>
    </Wrapper>
  )
}
