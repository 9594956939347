import React from 'react'

export const QA_VIKING = (
  <>
    Наш QA-инженер засветился в&nbsp;выпуске. <br />
    Да, он&nbsp;добрый викинг. Да, в&nbsp;отделе есть щит&nbsp;KODE.
  </>
)

export const ALIKHANOV = (
  <>
    Губернатор Калининградской области рассказывает о&nbsp;жизни и&nbsp;героях
    города, а&nbsp;также уделяет много внимания IT-индустрии.
    <br /> На фото &mdash; СЕО&nbsp;KODE Алексей&nbsp;Козак на&nbsp;встрече
    с&nbsp;губернатором.
  </>
)

export const DINA = (
  <>
    Известный&nbsp;гид рассказывает о&nbsp;самых уютных уголках города, локальных брендах и&nbsp;мероприятиях
  </>
)
