import React from 'react'
import { FluidObject } from 'gatsby-image'

import { useTheme, styled } from 'src/ui/theme'
import { Image } from 'src/ui/atoms'
import { Header } from 'src/ui/typography'

const Container = styled.div`
  position: relative;
`

const Children = styled.div<{
  childrenPosition?: 'bottom-left' | 'top-left' | 'bottom'
  width?: number
}>`
  padding-right: 16px;
  background: ${({ theme }) => theme.colors.white.primary};
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  ${({ childrenPosition }) =>
    childrenPosition === 'bottom-left'
      ? `
    
        position: absolute;
        bottom:0;
        left:0;
    `
      : childrenPosition === 'top-left'
      ? `
      position: absolute;
      top:0;
      left:0;
      padding-bottom: 40px;
      `
      : ''}
`

const Title = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  padding: 18px 72px 0 0;
  background: ${({ theme }) => theme.colors.white.primary};
  overflow: hidden;
`

type Props = {
  childrenPosition?: 'bottom-left' | 'top-left' | 'bottom'
  childrenWidth?: number
  title?: string
  image: FluidObject
  imageHeight: number
  objectPosition?: string
}

export const MosaicItem: React.FC<Props> = ({
  childrenPosition,
  image,
  imageHeight,
  objectPosition,
  children,
  childrenWidth,
  title,
}) => {
  const theme = useTheme()
  return (
    <Container>
      <Image
        fluid={image}
        objectPosition={objectPosition}
        height={imageHeight}
      />

      {children ? (
        <Children childrenPosition={childrenPosition} width={childrenWidth}>
          {children}
        </Children>
      ) : null}

      {title ? (
        <Title>
          <Header size="h4" as="h4" color={theme.colors.brand.primary}>
            {title}
          </Header>
        </Title>
      ) : null}
    </Container>
  )
}
