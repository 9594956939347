import React from 'react'

export const WEATHER = (
  <>
    Лето не сильно жаркое. Идеально для комфортной работы в&nbsp;городе
    и&nbsp;пляжного отдыха. Зимой идет снег, но&nbsp;сильные морозы бывают редко
    и&nbsp;воспринимаются местными жителями лишь как повод покататься на
    коньках.
  </>
)

export const INFRASTRUCTURE = (
  <>
    Уровень жизни — один из&nbsp;самых высоких среди городов России после Москвы
    и&nbsp;Санкт-Петербурга. Калининград{' '}
    <a
      target="_blank"
      href="https://kaliningrad.rbc.ru/kaliningrad/07/01/2021/5ff6a2789a7947473edaed07"
    >
      входит в&nbsp;десятку
    </a>{' '}
    самых комфортных городов для&nbsp;жизни и&nbsp;{' '}
    <a
      target="_blank"
      href="https://kgd.ru/news/society/item/101079-kaliningrad-voshjol-v-pyatjorku-samyh-privlekatelnyh-gorodov-dlya-pereezda-radi-raboty"
    >
      считается привлекательным городом
    </a>{' '}
    для&nbsp;переезда ради работы.
  </>
)

export const TRAVEL = (
  <>
    Во&nbsp; время открытых границ из Калининграда за&nbsp;пару часов
    и&nbsp;700&nbsp;рублей можно добраться до ближайшего польского аэропорта
    и&nbsp;лоукостером улететь в&nbsp;большинство городов&nbsp;Европы.
  </>
)

export const ENVIRONMENT = (
  <>
  Город окружен красивыми природными ландшафтами, лесами и озерами. Возможность часто бывать на природе и заниматься активным отдыхом, таким как пешие прогулки, велосипедные поездки и рыбалка, делает жизнь в Калининграде более привлекательной.
  </>
)

export const CULTURE = (
  <>
    Всегда можно найти способ провести время после работы и&nbsp;в&nbsp;выходные
    дни.{' '}
    <a href="https://klops.ru/afisha" target="_blank">
      Афиша&nbsp;мероприятий
    </a>{' '}
    пестрит событиями, а&nbsp;в&nbsp;летний сезон один фестиваль сменяет другой.
    <br />
    <br />
    Например, в мае проходит кинофестиваль «Край света. Запад»,
    в&nbsp;августе&nbsp;&mdash;&nbsp;музыкальный опен-эйр
    «Калининград&nbsp;Сити&nbsp;Джаз», где выступают звёзды джаз
    и&nbsp;хип-хоп&nbsp;сцены. Затем фестиваль короткометражек «Короче» собирает
    артистов кино и&nbsp;открывает кинотеатр под открытым небом
    в&nbsp;Центральном&nbsp;парке.
  </>
)

export const SIGHTSEEINGS = (
  <>
    Всё&nbsp;это располагает к&nbsp;созерцанию и&nbsp;отдыху. Наследие бывшего
    Кёнигсберга стало частью повседневной жизни&nbsp;города.
  </>
)

export const MORE_REASONS = 'И это ещё не все причины'
