import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { useTheme } from 'src/ui/theme'
import { Hero, HeroLine } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const LifeLevel: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  return (
    <>
      <Wrapper>
        <Header size="h4" as="h2">
          Уровень жизни
        </Header>
      </Wrapper>
      <HBox height={24} />
      <Hero
        title="25 000 ₽"
        background={images['rent']}
        titleColor={theme.colors.brand.primary}
        textHeight={219}
      >
        <HBox height={8} />
        <Body size="secondary">
          Оплатить аренду квартиры с&nbsp;учетом коммуналки
        </Body>
      </Hero>
      <HBox height={32} />
      <HeroLine
        align="right"
        background={images['taxi']}
        title="150 ₽"
        titleColor={theme.colors.brand.primary}
        description="Поехать на такси"
      />
      <HBox height={32} />
      <Hero
        title="79 ₽"
        background={images['train']}
        titleColor={theme.colors.brand.primary}
        textHeight={219}
      >
        <HBox height={8} />
        <Body size="secondary">Поехать на&nbsp;электричке на&nbsp;море</Body>
      </Hero>
      <HBox height={32} />
      <HeroLine
        align="left"
        background={images['bar']}
        title="700 ₽"
        titleColor={theme.colors.brand.primary}
        description="Сходить в бар, выпить пива и&nbsp;съесть пиццу."
      />
      <HBox height={32} />
      <Hero
        title="1 200 ₽"
        background={images['supermarket']}
        titleColor={theme.colors.brand.primary}
        textHeight={219}
      >
        <HBox height={8} />
        <Body size="secondary">Купить продукты домой в&nbsp;супермаркете</Body>
      </Hero>
    </>
  )
}
