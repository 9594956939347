import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'

import { Body, Header } from 'src/ui/typography'
import { MosaicItem } from './../../molecules'

type Props = {
  images: TImages
}

const Grid1 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 43% 53%;
  gap: 4%;
`

const Grid2 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 70% 26%;
  gap: 4%;
`

export const Rest: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Header size="h2" as="h2">
        Досуг и&nbsp;отдых
      </Header>
      <HBox height={40} />
      <Grid1>
        <MosaicItem
          image={images['events-desk']}
          imageHeight={274}
          childrenPosition="bottom-left"
          childrenWidth={218}
          objectPosition="top right"
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            29
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Крупных событий проходит <br />
            в&nbsp;Калининграде ежегодно
          </Body>
        </MosaicItem>

        <MosaicItem
          image={images['free-events-desk']}
          imageHeight={274}
          childrenPosition="bottom-left"
          childrenWidth={318}
          objectPosition="top left"
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            0&nbsp;₽
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Большинство <br />
            мероприятий бесплатныe
          </Body>
        </MosaicItem>
      </Grid1>
      <HBox height={40} />
      <Grid2>
        <MosaicItem
          image={images['visa-desk']}
          imageHeight={442}
          childrenPosition="bottom-left"
          childrenWidth={318}
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            27
          </Header>
          <HBox height={8} />
          <Body size="secondary">Пляжей в&nbsp;области</Body>
        </MosaicItem>

        <MosaicItem
          image={images['skyserf']}
          imageHeight={302}
          objectPosition="top center"
        >
          <HBox height={24} />
          <Header size="h2" color={theme.colors.brand.primary}>
            3&nbsp;000&nbsp;₽
          </Header>
          <HBox height={8} />
          <Body size="secondary">
            Взять урок сап-сёрфинга <br />
            с&nbsp;прокатом оборудования
          </Body>
        </MosaicItem>
      </Grid2>
    </Wrapper>
  )
}
