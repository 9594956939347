import React from 'react'
import { breakpoints } from 'src/ui/dimensions'

import { styled } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Image } from 'src/ui/atoms'
import { HeroDesktopSecondary } from 'src/ui/organisms'

import { Body, Header } from 'src/ui/typography'
import {
  CULTURE,
  ENVIRONMENT,
  INFRASTRUCTURE,
  MORE_REASONS,
  SIGHTSEEINGS,
  TRAVEL,
  WEATHER,
} from './constants'

const Row = styled.div`
  display: grid;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const Row3 = styled.div`
  display: grid;

  grid-template-columns: 43.9% 24.2% 24.2%;
  gap: 3.8%;
`

const Col = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const Title = styled.div`
  display: grid;
  grid-template-columns: 50% 46%;
  gap: 4%;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr;
  }
`

const VideoBlock = styled.div`
  position: relative;
`

const VideoBlockTitle = styled.div<{ top: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  right: 0;
  width: 368px;
  padding: 64px 40px;
  background: ${({ theme }) => theme.colors.white.primary};
  transition: top 0.3s;
`

const Digit = styled.span`
  font-size: 48px;
`
const Heading = styled.div`
  position: relative;
  padding-left: 36px;
  min-height: 58px;
  ${Digit} {
    position: absolute;
    left: 0;
    top: 0;
  }
`

type Props = {
  images: TImages
}

export const Reasons: React.FC<Props> = ({ images }) => {
  const [videoActive, setVideoActive] = React.useState(false)

  return (
    <>
      <HBox height={74} />
      <Wrapper>
        <Title>
          <Col>
            <Header size="h2" as="h2">
              Пять причин жить в&nbsp;Калининграде
            </Header>
          </Col>

          <Col>
            <HBox height={8} />
          </Col>
        </Title>
        <HBox height={64} />
        <Row>
          <Col>
            <Heading>
              <Header size="h4" as="h2">
                <Digit>1</Digit> Комфортный климат и&nbsp;погода
              </Header>
            </Heading>
            <HBox />
            <Body>{WEATHER}</Body>
            <HBox height={24} />
          </Col>

          <Col>
            <Image height={245} fluid={images['city']} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Image
              height={245}
              fluid={images['climat']}
              objectPosition="bottom center"
            />
          </Col>
          <Col>
            <HBox height={40} />
            <Heading>
              <Header size="h4" as="h2">
                <Digit>2</Digit> Качество инфраструктуры и&nbsp;сервиса
              </Header>
            </Heading>
            <HBox />
            <Body>{INFRASTRUCTURE}</Body>
            <HBox height={24} />
          </Col>
        </Row>

        <Row3>
          <Col>
            <HBox height={40} />
            <Heading>
              <Header size="h4" as="h2">
                <Digit>3</Digit> Природа и экология
              </Header>
            </Heading>
            <HBox />
            <Body>{ENVIRONMENT}</Body>
            <HBox height={24} />
          </Col>

          <Col>
            <Image height={245} fluid={images['castel']} />
          </Col>
          <Col>
            <Image height={245} fluid={images['europe']} />
          </Col>
        </Row3>

        <Row>
          <Col>
            <Image height={346} fluid={images['culture']} />
          </Col>
          <Col>
            <HBox height={54} />
            <Heading>
              <Header size="h4" as="h2">
                <Digit>4</Digit> Культурная жизнь
              </Header>
            </Heading>
            <HBox />
            <Body>{CULTURE}</Body>
            <HBox height={40} />
          </Col>
        </Row>
      </Wrapper>
      <HeroDesktopSecondary
        contentPosition="top-left"
        background={images['museum-desk']}
        adornment={false}
        height={529}
        contentWidth={47.7}
      >
        <HBox height={40} />
        <Heading>
          <Header size="h4" as="h2">
            <Digit>5</Digit> Море, средневековые рыцарские замки,
            старинные&nbsp;кирхи, вековые&nbsp;аллеи
          </Header>
        </Heading>
        <HBox />
        <Body
          style={{
            maxWidth: '625px',
          }}
        >
          {SIGHTSEEINGS}
        </Body>
      </HeroDesktopSecondary>
      <HBox height={130} />
      <Wrapper>
        <VideoBlock>
          <HeroDesktopSecondary
            adornment={false}
            height={724}
            videoId={'w_qDdKGFlRM'}
            onPause={() => setVideoActive(false)}
            onPlay={() => setVideoActive(true)}
          />
          <VideoBlockTitle top={videoActive ? -302 : 0}>
            <Header size="h2" as="h2">
              {MORE_REASONS}
            </Header>
          </VideoBlockTitle>
        </VideoBlock>
      </Wrapper>
    </>
  )
}
