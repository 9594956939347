import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Kaliningrad: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['kaliningrad']}
    title="Европа в России"
    adornment={true}
    titleSize="h2"
    titleAs="h1"
    objectPosition="top center"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '640px',
      }}
    >
      Калининград иногда называют русской&nbsp;Европой, поскольку на&nbsp;этой
      территории причудливо соединяются европейский образ жизни, русские
      традиции, старинные улочки бывшего Кёнигсберга и&nbsp;современные офисы
      IT-компаний.
    </Body>
  </HeroDesktopSecondary>
)
